<template>
  <div class="user-setting style-box-setting">
    <div class="box-content">
      <div class="card-block-filter-setting">
        <div class="right-block-filter">
          <v-select
            placeholder="Role"
            :items="[{ text: 'Tous', value: null }, ...listType]"
            label="Role"
            v-model="role"
            outlined
            clearable
            @change="FilterGetUser"
            :persistent-placeholder="true"
            dense
            :menu-props="{ bottom: true, offsetY: true }"
            color="#704ad1"
            item-color="#704ad1"
          >
          </v-select>
          <v-progress-circular
            class="ml-2"
            v-if="getUserLoading"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </div>
        <div class="left-block-filter">
          <div class="search-input">
            <search
              v-if="checkPermission('GUHTCU')"
              :value="searchValue"
              @changeSearchValue="changeSearchValue"
              hiddenContent="true"
            />
          </div>
          <v-tooltip bottom color="#311B92" v-if="checkPermission('GUHTALU')">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center mr-1"
                v-on="on"
                v-bind="attrs"
                @click.stop="handlClickActive('no')"
              >
                <font-awesome-icon icon="user" />
              </div>
            </template>
            <span>Utilisateur non supprimée</span>
          </v-tooltip>
          <v-tooltip bottom color="#311B92" v-if="checkPermission('GUHTALUS')">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center mr-1"
                v-on="on"
                v-bind="attrs"
                @click.stop="handlClickActive('yes')"
              >
                <font-awesome-icon icon="user-slash" />
              </div>
            </template>
            <span>Utilisateur supprimée</span>
          </v-tooltip>
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center"
                v-on="on"
                v-bind="attrs"
                v-if="checkPermission('GUTHAOU')"
                @click.prevent="addUserModal = true"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span>Ajouter un utilisateur</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div class="table-gestion-content ">
      <v-data-table
        v-model="selectedTable"
        :headers="computedListFields"
        :items="computedUsers"
        :items-per-page="perPage"
        class="table-gestion-client-passage"
        hide-default-footer
        disable-sort
        :calculate-widths="false"
        :fixed-header="true"
        hide-action
        :no-data-text="
          getUserLoading
            ? 'Chargement... Veuillez patienter'
            : `Il n'y a aucune donnée à afficher dans ce tableau.`
        "
      >
        <template v-slot:[`item.Actions`]="{ item }">
          <td class="custom-cell">
            <v-btn
              v-if="item.deleted == false && checkPermission('GUTHMU')"
              small
              class="block-icon-reglement-fac mr-2"
              @click.prevent.stop="handleClickUpdate(item)"
              title="Modifier"
            >
              <font-awesome-icon icon="pencil-alt" />
            </v-btn>
            <v-btn
              v-if="getUserData.role == 'admin'"
              small
              class="block-icon-reglement-fac mr-2"
              @click.prevent.stop="handleClickLogin(item)"
              title="Se connecter"
              :loading="item.loaderChangeCompte"
            >
              <font-awesome-icon icon="file-export"
            /></v-btn>
            <v-btn
              v-if="item.deleted == false && checkPermission('GUTHSU')"
              small
              class="block-icon-reglement-fac"
              @click.prevent.stop="handleClickDelete(item)"
              title="Désactiver"
            >
              <font-awesome-icon icon="trash" />
            </v-btn>
            <v-btn
              v-if="item.deleted == true && checkPermission('GUTHRU')"
              small
              class="block-icon-reglement-fac"
              @click.prevent.stop="handleClickRestaurer(item)"
              title="Activer"
            >
              <font-awesome-icon icon="arrow-alt-circle-left" />
            </v-btn>
          </td>
        </template>
      </v-data-table>
      <div class="footer-style-table">
        <div class="text-center pagination-table">
          <v-pagination
            v-model="page"
            :length="computedTotal"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            @input="pagination"
            :total-visible="7"
          ></v-pagination>
        </div>
        <div class="select-input-vuetify">
          <v-select
            v-model="perPage"
            :items="perPageList"
            @change="changePerPage"
            label="Eléments par page"
            outlined
            dense
            hide-details
            no-data-text="Aucun élément trouvé"
            :menu-props="{
              left: true,
              offsetX: true
            }"
            color="#704ad1"
            item-color="#704ad1"
          ></v-select>
        </div>
      </div>
    </div>
    <!-- add user modal  -->
    <v-dialog
      v-model="addUserModal"
      max-width="820px"
      hide-overlay
      persistent
      scrollable
      content-class="custom-vuetify-dialog-gestion"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"> Ajouter un utilisateur </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('addUserModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>
        <v-card-text class="body-card center-text  mt-2">
          <v-form class="mt-2" ref="form" lazy-validation>
            <v-row class="mt-2">
              <v-col>
                <v-text-field
                  label="Nom *"
                  :persistent-placeholder="true"
                  v-model="userToAdd.name"
                  dense
                  outlined
                  required
                  :auto-focus="false"
                  class="msg-error"
                  validate-on-blur
                  :rules="[v => !!v || 'Nom est obligatoire']"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="E-mail *"
                  :persistent-placeholder="true"
                  v-model="userToAdd.email"
                  required
                  :rules="[v => !!v || 'Adresse Email est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  type="email"
                  color="#704ad1"
                >
                </v-text-field
              ></v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col cols="12">
                <v-text-field
                  label="Téléphone"
                  :persistent-placeholder="true"
                  v-model="userToAdd.phone"
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col>
                <v-text-field
                  label="Mot de passe"
                  :persistent-placeholder="true"
                  v-model="userToAdd.password"
                  dense
                  outlined
                  type="password"
                  color="#704ad1"
                >
                </v-text-field
              ></v-col>
              <v-col
                ><v-text-field
                  label="Confirmation de mot de passe"
                  :persistent-placeholder="true"
                  v-model="userToAdd.confirmationPassword"
                  dense
                  outlined
                  type="password"
                  color="#704ad1"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col
                ><v-select
                  :items="computedListeProfile"
                  v-model="userToAdd.profile"
                  label="Profile"
                  dense
                  outlined
                  no-data-text="Aucune option trouvé"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                  :persistent-placeholder="true"
                ></v-select
              ></v-col>
              <v-col>
                <v-select
                  :persistent-placeholder="true"
                  :items="listType"
                  v-model="userToAdd.role"
                  label="type"
                  dense
                  outlined
                  no-data-text="Aucune option trouvé"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select
              ></v-col>
            </v-row>

            <v-row class="mt-2 ">
              <v-col cols="12">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="userToAdd.societe_traitement"
                  :items="getGestionSocieteAtraiter"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Gestion sociéte à traiter"
                  item-text="name"
                  multiple
                  chips
                  return-object
                  outlined
                  :deletable-chips="true"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  :small-chips="true"
                  :clearable="true"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                ></v-autocomplete
              ></v-col>
            </v-row>
            <v-row class="mt-2 ">
              <v-col cols="12">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="userToAdd.filiales"
                  :items="comptedgetListFilialesLocateur"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Gestion sociéte"
                  item-text="name"
                  multiple
                  chips
                  return-object
                  outlined
                  :deletable-chips="true"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  :small-chips="true"
                  :clearable="true"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                ></v-autocomplete
              ></v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="loading"
            :class="{ loader: loading }"
            @click.prevent.stop="hendleSubmitUser"
            >Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('addUserModal')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
    <!-- End add user modal  -->
    <!-- update user modal  -->
    <v-dialog
      v-model="userUpdateModal"
      max-width="820px"
      hide-overlay
      persistent
      scrollable
      content-class="custom-vuetify-dialog-gestion"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            Modifier {{ userToUpdate ? userToUpdate.full_name : '' }}</v-label
          >
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('userUpdateModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>
        <v-card-text class="body-card center-text  mt-2" v-if="userToUpdate">
          <v-form class="mt-2" ref="form" lazy-validation>
            <v-row class="mt-2">
              <v-col>
                <v-text-field
                  label="Nom *"
                  :persistent-placeholder="true"
                  v-model="userToUpdate.full_name"
                  dense
                  outlined
                  required
                  :auto-focus="false"
                  class="msg-error"
                  validate-on-blur
                  :rules="[v => !!v || 'Nom est obligatoire']"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="E-mail *"
                  :persistent-placeholder="true"
                  v-model="userToUpdate.email"
                  required
                  :rules="[v => !!v || 'Adresse Email est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  type="email"
                  color="#704ad1"
                >
                </v-text-field
              ></v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col cols="12">
                <v-text-field
                  label="Téléphone"
                  :persistent-placeholder="true"
                  v-model="userToUpdate.phone"
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col>
                <v-text-field
                  label="Mot de passe"
                  :persistent-placeholder="true"
                  v-model="userToUpdate.password"
                  dense
                  outlined
                  type="password"
                  color="#704ad1"
                >
                </v-text-field
              ></v-col>
              <v-col
                ><v-text-field
                  label="Confirmation de mot de passe"
                  :persistent-placeholder="true"
                  v-model="userToUpdate.confirmationPassword"
                  dense
                  outlined
                  type="password"
                  color="#704ad1"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col
                ><v-select
                  :items="computedListeProfile"
                  v-model="userToUpdate.profil_id"
                  label="Profile"
                  dense
                  outlined
                  no-data-text="Aucune option trouvé"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select
              ></v-col>
              <v-col>
                <v-select
                  :items="listType"
                  v-model="userToUpdate.type"
                  label="type"
                  dense
                  outlined
                  no-data-text="Aucune option trouvé"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select
              ></v-col>
            </v-row>

            <v-row class="mt-2 ">
              <v-col cols="12">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="userToUpdate.societe_traitement"
                  :items="getGestionSocieteAtraiter"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Gestion sociéte à traiter"
                  item-text="name"
                  multiple
                  chips
                  return-object
                  outlined
                  :deletable-chips="true"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  :small-chips="true"
                  :clearable="true"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                ></v-autocomplete
              ></v-col>
            </v-row>
            <v-row class="mt-2 ">
              <v-col cols="12">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="userToUpdate.filiales"
                  :items="comptedgetListFilialesLocateur"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Gestion sociéte"
                  item-text="name"
                  multiple
                  chips
                  return-object
                  outlined
                  :deletable-chips="true"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  :small-chips="true"
                  :clearable="true"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                ></v-autocomplete
              ></v-col>
            </v-row>
          </v-form>

          <v-row class="mt-4">
            <v-col cols="12">
              <v-tabs
                align-with-title
                background-color="#f1edfb"
                color="#704ad1"
              >
                <v-tab active>
                  <span class="tab-text-bold">
                    Liste des sociétes à traiter
                  </span>
                </v-tab>
                <v-tab-item class="tab-tranche-paiement">
                  <v-row
                    class="mt-2"
                    v-if="userToUpdate && userToUpdate.societe_traitement"
                  >
                    <v-row>
                      <div
                        v-for="(item, index) in userToUpdate.societe_traitement"
                        :key="'societe-traiter-' + index"
                        class="card-Societe-traiter "
                      >
                        <div class="mb-1">
                          <div class="filiale-list-user mb-2">
                            <span>
                              <font-awesome-icon
                                icon="check-double"
                                class="mr-2"
                              />{{ item.name }}</span
                            >
                          </div>
                          <div class="card-societe_traiter-body">
                            <div
                              v-if="
                                computedSelectedSocieteTraite(item.filiales) &&
                                  computedSelectedSocieteTraite(item.filiales)
                                    .length
                              "
                            >
                              <div class="title ">Les sociétes</div>
                            </div>
                            <div
                              class="card-societe_traiter-item"
                              v-for="(s,
                              indexS) in computedSelectedSocieteTraite(
                                item.filiales
                              )"
                              :key="indexS + 'soc'"
                            >
                              <div>{{ s.filiale }}</div>
                            </div>
                            <div
                              v-if="
                                computedSelectedSocieteTraite(
                                  item.interfaces
                                ) &&
                                  computedSelectedSocieteTraite(item.interfaces)
                                    .length
                              "
                            >
                              <div class="title">Les interfaces</div>
                            </div>
                            <div
                              class="card-societe_traiter-item"
                              v-for="(i,
                              indexI) in computedSelectedSocieteTraite(
                                item.interfaces
                              )"
                              :key="indexI + 'inter'"
                            >
                              <div>{{ i.interface }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-row>
                  </v-row>
                </v-tab-item>
                <v-tab>
                  <span class="tab-text-bold">
                    Liste des sociétes
                  </span>
                </v-tab>
                <v-tab-item class="tab-tranche-paiement">
                  <v-row class="mt-2 " style="padding:20px;">
                    <div v-if="userToUpdate.filiales.length == 0">
                      Aucune sociéte trouvé
                    </div>
                    <div class="filiale-list-user mb-1">
                      <div
                        v-for="(e, index) in userToUpdate.filiales"
                        :key="index"
                        class="mb-1"
                      >
                        <span>
                          <font-awesome-icon
                            icon="check-double"
                            class="mr-2"
                          />{{ e.name }}</span
                        >
                      </div>
                    </div>
                  </v-row>
                </v-tab-item>
              </v-tabs></v-col
            ></v-row
          >
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="isLoading"
            :class="{ loader: isLoading }"
            @click.prevent.stop="handleSubmitUpdate"
            >Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('userUpdateModal')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
    <!-- End update user modal  -->
    <!-- Delete User Modal -->
    <v-dialog
      v-model="deletedUserModal"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Supprimer un utilisateur</v-label>

          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('deletedUserModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div class="mt-4 ml-3">
            <p>
              Êtes-vous certain de vouloir supprimer l'utilisateur
              <span class="bold-text">{{
                userToDelete ? userToDelete.full_name : ''
              }}</span>
            </p>
          </div>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="isLoading"
            :class="{ loader: isLoading }"
            @click.prevent.stop="handleModalSupprimerValider"
            >Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('deletedUserModal')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Delete User Modal -->
    <!-- Restaurer User Modal -->
    <v-dialog
      v-model="resetdUserModal"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">restaurer un utilisateur</v-label>

          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('resetdUserModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div class="mt-4 ml-3">
            <p>
              Êtes-vous sûr de vouloir restaurer cet utilisateur
              <span class="bold-text">{{
                userToRestaurer ? userToRestaurer.full_name : ''
              }}</span>
            </p>
          </div>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="isLoading"
            :class="{ loader: isLoading }"
            @click.prevent.stop="handleRestaurerUser"
            >Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('resetdUserModal')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Restaurer User Modal -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import SelectSimpleWithSearch from '../../component/selectSimpleWithSearch.vue';
export default {
  data() {
    return {
      deletedUserModal: false,
      resetdUserModal: false,
      addUserModal: false,
      userToAdd: {
        nom: null,
        email: null,
        phone: null,
        password: null,
        confirmationPassword: null,
        profile: null,
        type: null,
        societe_traitement: [],
        filiales: []
      },
      selectedTable: [],
      searchValue: null,
      isLoading: false,
      userToUpdate: null,
      userUpdateModal: false,
      oldUserToUpdate: null,
      userToDelete: null,
      page: 1,
      perPage: 10,
      showErrorUser: false,
      role: null,
      deleted: 'no',
      userToRestaurer: null,
      loading: null,
      error: null,

      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      sort_by: 'first_name',
      full_name: null,
      listType: [
        { text: 'Comptabilité', value: 'contabilite' },
        { value: 'responsable_filiale', text: 'Responsable Filiale' },
        {
          value: 'responsable_support',
          text: 'Responsable Support'
        }
      ]
    };
  },
  methods: {
    ...mapActions([
      'getUsers',
      'updateUser',
      'deleteUser',
      'restaurerUser',
      'getAllRoles',
      'getTokenAnotherAccount',
      'fetchBadgeNumberReglement',
      'addUser',
      'getAllFilialesListe'
    ]),
    async hendleSubmitUser() {
      this.loading = true;
      this.error = null;
      if (this.userToAdd.password != this.userToAdd.confirmationPassword) {
        this.loading = false;
        this.error = [
          {
            password:
              'le mot de passe et la confirmation du mot de passe doivent être égaux'
          }
        ];
        return;
      }
      if (this.userToAdd?.password?.length < 6) {
        this.loading = false;
        this.error = [
          { password: 'le mot de passe doit contenir au moins 6 caractères' }
        ];
        return;
      }
      const responce = await this.addUser(this.userToAdd);
      if (responce.success) {
        this.loading = false;
        this.hideModal('addUserModal');
      } else {
        this.loading = false;
        this.error = responce.error;
      }
    },
    async handleClickLogin(data) {
      data.loaderChangeCompte = true;
      const response = await this.getTokenAnotherAccount(data.id);
      if (response.success) {
        this.fetchBadgeNumberReglement();
        if (this.checkPermission('SGCDG')) {
          this.$router.push({ name: 'statistics-general' }).catch(() => {});
        } else {
          this.$router.push({ name: 'cdg' }).catch(() => {});
        }
        data.loaderChangeCompte = false;
      }
    },
    async changePerPage() {
      this.page = 1;
      await this.FilterGetUser({ per_page: this.perPage, page: this.page });
    },
    async pagination(paginate) {
      this.page = paginate;
      await this.FilterGetUser({ per_page: this.perPage, page: this.page });
    },
    resetModal() {
      this.userToAdd = {
        name: null,
        email: null,
        phone: null,
        password: null,
        confirmationPassword: null,
        role: null,
        societe_traitement: [],
        filiales: []
      };
      this.userToUpdate = null;
      this.oldUserToUpdate = null;
      this.userToDelete = null;
      this.userToRestaurer = null;
      this.loading = false;
      this.error = null;
    },
    hideModal(ref) {
      // this.$refs[ref].hide();
      this[ref] = false;
      this.resetModal();
    },
    FilterGetUser() {
      this.getUsers({
        role: this.role,
        deleted: this.deleted,
        search: this.searchValue,
        per_page: this.perPage,
        page: this.page
      });
      this.setLocalStorageParamsUsers();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.page = 1;
      this.FilterGetUser();
      this.setLocalStorageParamsUsers();
    },
    handlClickActive(data) {
      this.page = 1;
      this.deleted = data;
      this.FilterGetUser();
      this.setLocalStorageParamsUsers();
    },
    handleChange() {
      this.page = 1;
      this.FilterGetUser();
    },
    handleClickUpdate(user) {
      this.userUpdateModal = true;
      this.userToUpdate = { ...user };
      this.oldUserToUpdate = user;
      // this.$refs['userUpdateModal'].show();
    },
    async handleSubmitUpdate() {
      this.isLoading = true;
      this.error = null;
      const response = await this.updateUser({
        userToUpdate: this.userToUpdate,
        old: this.oldUserToUpdate
      });
      if (response.success) {
        this.isLoading = false;

        this.hideModal('userUpdateModal');
      } else {
        this.isLoading = false;

        this.error = response.error;
      }
    },
    handleClickDelete(user) {
      this.userToDelete = { ...user };
      this.deletedUserModal = true;
    },

    async handleModalSupprimerValider() {
      this.isLoading = true;
      this.error = null;
      const response = await this.deleteUser(this.userToDelete.id);
      if (response.success) {
        this.isLoading = false;
        this.hideModal('deletedUserModal');
      } else {
        this.isLoading = false;
        this.error = response.error;
      }
    },
    handleClickRestaurer(user) {
      this.userToRestaurer = user;
      this.resetdUserModal = true;
    },
    async handleRestaurerUser() {
      this.isLoading = true;
      this.error = null;
      const response = await this.restaurerUser(this.userToRestaurer);
      if (response.success) {
        this.isLoading = false;
        this.hideModal('resetdUserModal');
      } else {
        this.isLoading = false;
        this.error = response.error;
      }
    },
    setLocalStorageParamsUsers() {
      localStorage.setItem(
        'Params-users',
        JSON.stringify({
          role: this.role,
          deleted: this.deleted,
          search: this.searchValue
        })
      );
    }
    // pagination(paginate) {
    //   this.page = paginate;
    //   this.FilterGetUser();
    // },
    // changePerPage() {
    //   this.page = 1;
    //   this.FilterGetUser();
    // }
  },
  components: {
    // Card: () => import('../../component/card'),
    search: () => import('@/views/component/SearchInputComponent.vue')
    // SelectSimpleWithSearch
  },
  filters: {
    FiterType: value => {
      switch (value) {
        case 'contabilite':
          return 'Comptabilité';
        case 'responsable_dnh':
          return 'Responsable GE CDG';
        case 'responsable_web_abondan':
          return 'Responsable PINKMAN';
        case 'responsable_acteo':
          return ' Responsable ALTEO FRANCE';
        case 'responsable_sav_s':
          return ' Responsable SAMI-B';

        default:
          return (value.charAt(0).toUpperCase() + value.slice(1)).replace(
            /_/g,
            ' '
          );
      }
    }
  },
  mounted() {
    this.getAllRoles();
    if (localStorage.getItem('Params-users')) {
      this.role = JSON.parse(localStorage.getItem('Params-users')).role;
      this.deleted = JSON.parse(localStorage.getItem('Params-users')).deleted;
      this.searchValue = JSON.parse(
        localStorage.getItem('Params-users')
      ).search;
    } else {
      this.setLocalStorageParamsUsers();
    }
    this.FilterGetUser({ per_page: this.perPage, page: this.page });
    this.getAllFilialesListe();
  },

  computed: {
    ...mapGetters([
      'users',
      'getUserLoading',
      'getUsersCount',
      'getUsersTotlaRows',
      'computedListeProfile',
      'roles',
      'checkPermission',
      'getUserData',
      'getGestionSocieteAtraiter',
      'getListFilialesLocateur'
    ]),
    comptedgetListFilialesLocateur() {
      return this.getListFilialesLocateur.filter(i => i.type != 'passage');
    },
    computedTotal() {
      if (this.getUsersTotlaRows) {
        return Math.ceil(this.getUsersTotlaRows / this.perPage);
      }
      return this.getUsersTotlaRows;
    },
    computedSelectedSocieteTraite() {
      return function(item) {
        if (item) {
          let tab = [...item];
          return tab.filter(i => i.checked == true);
        }
      };
    },
    computedListFields() {
      let fields = [
        {
          value: 'full_name',
          text: 'Nom',
          show: this.checkPermission('GUHTACN')
        },
        {
          value: 'email',
          text: 'E-mail',
          show: this.checkPermission('GUHTACEM')
        },
        { value: 'type', text: 'Type', show: this.checkPermission('GUHTACTY') },
        {
          value: 'phone',
          text: 'Télephone',
          show: this.checkPermission('GUHTACTT')
        },
        { value: 'Actions', text: 'Actions', show: true }
      ];

      return fields.filter(field => field.show == true);
    },
    computedUsers() {
      let deleted = this.deleted == 'yes' ? true : false;
      return this.users.filter(item => item.deleted == deleted);
    },
    computedListeProfile: function() {
      return this.roles.map(role => {
        return {
          text: role.name,
          value: role.id,
          disabled: role.blocked == true ? true : false
        };
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.user-setting {
  // padding: 15px;
  padding-top: 0px;
  overflow: hidden;
  width: 100%;

  .box-content {
    padding-top: 0px !important;
  }
  .table-users-content {
    max-height: calc(100vh - 253px) !important;
    height: calc(100vh - 253px) !important;
  }
}

.filiale-list-user {
  text-align: start;
  color: #747070;
  margin-left: 20px;
  font-size: 12.5px;
}

.card-Societe-traiter {
  // border: 1px solid rgba(108, 117, 125, 0.30196078431372547);
  margin-right: 8px;
  // background-color: #332fc838;
  border-radius: 4px;
  display: table-cell;
  vertical-align: top;
  padding: 2px;
  margin: 5px;
  width: 181px;
  color: #000;
  margin-bottom: 10px;

  .card-societe-traiter-header {
    position: relative;
    width: 100%;
    padding: 0px;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    background-color: rgb(245 244 250);
    padding: 5px;
  }

  .card-societe_traiter-body {
    margin-left: 41px;

    .card-societe_traiter-item {
      position: relative;
      overflow: visible;
      display: flex;
      justify-content: space-between;
      margin-top: 0px;
      // border: 1px solid rgba(108, 117, 125, 0.3215686274509804);
      padding: 4px;

      width: 100%;
      border-radius: 4px;
      font-size: 9px;
      font-weight: 500;
      font-family: 'Montserrat', sans-serif;
      color: black;
    }
    .title {
      text-align: left;
      font-size: 11px !important;
    }
  }
}
</style>
<style lang="scss">
.tabs-class-societ_traiter {
  .nav-tabs .nav-link {
    background-color: #f6f6f6 !important;
    border: none;
    color: black !important;
    font-size: 12px !important;
    font-weight: 800 !important;
  }

  .nav-tabs .nav-link.active {
    background-color: #908fb3 !important;
    border: none;
    color: #fff !important;
  }
}
</style>
